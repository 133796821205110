










































































import LearnitUserResourceViewModel from '@/src/services/viewModel/resource/LearnitUserResourceViewModel';
import UserResourceViewModel from '@glittr/frontend-core/src/services/viewModel/resource/UserResourceViewModel';
import UserSetPasswordRequestViewModel from '@glittr/frontend-core/src/services/viewModel/resource/UserSetPasswordRequestViewModel';
import Vue from 'vue';

export default Vue.extend({
  auth: true,
  data: () => ({
    resetPassword: new UserSetPasswordRequestViewModel(),
    requestError: undefined as any,
    resetPasswordValidationMessage: (undefined as undefined | string),
    newPasswordError: undefined as undefined | string,
    repeatPasswordError: undefined as undefined | string,
    isSaving: false,
    learnitUser: new LearnitUserResourceViewModel(),
    user: new UserResourceViewModel(),
  }),
  mounted() {
    this.$set(this.$store.values, 'title', 'USER PROFILE');
    this.loadLearnitUser();
  },
  methods: {
    async loadLearnitUser() {
      this.learnitUser = await this.$service.api.users.getLearnitUser();
      this.user = this.$auth.user ?? {} as any;
    },
    async doLogoff() {
      try {
        await this.$auth.logout();
        Vue.$router.push('/_exterior/login');
      } catch (error) {
        this.requestError = `${error}`;
      }
    },
    async save() {
      this.isSaving = true;
      try {
        await this.$service.api.users.updateLearnitUser(this.learnitUser);
      } catch (_e: any) {
        const e:Error = _e;
        this.requestError = e.message;
      }
      this.isSaving = false;
    },
    async doResetPassword() {
      try {
        this.newPasswordError = undefined;
        this.repeatPasswordError = undefined;
        this.resetPassword.email = this.user.email!;
        // TODO: - can be validated through control
        if (!this.resetPassword.newPassword || this.resetPassword.newPassword.length < this.$config.values['auth-min-password-length']) {
          this.newPasswordError = this.$t('core.validation.minLength', this.$config.values['auth-min-password-length']);
          return;
        }
        if (this.resetPassword.newPassword !== this.resetPassword.repeatNewPassword) {
          this.repeatPasswordError = this.$t('core.validation.sameAs');
          return;
        }

        await this.$service.api.core.me.setPassword(this.resetPassword)
          .then(() => {
            this.doLogoff();
          });
      } catch (error) {
        this.requestError = error;
      }
    },
  },
});
